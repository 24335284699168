import './App.css';

function App() {
  return (
    <div id="page-top">
      <div id="apDiv2">
        <a href="#myModal">
          <img src="img/download.png" alt="Wadhwa Wise City Brochure" title="Wadhwa Wise City brochure" className="apDiv1"  data-toggle="modal" data-target="#myModal"/>
          </a>
      </div>

      <div id="apDiv4">
        <div className="apDiv4-call" data-toggle="modal" data-target="#myModals">
          <i className="fa fa-car faa-tada animated"></i>&ensp;&ensp;Site Visit
        </div>
      </div>
      <div id="apDiv5">
        <div className="apDiv5-call"><a href="tel:+919130568016">
          <i className="fa fa-phone faa-tada animated"></i>&ensp;+91 9130568016</a>
        </div>
      </div>

      <div id="myModal" className="modal fade" role="dialog">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                  <h4 className="modal-title pop_align">Get in touch for Wadhwa Wise City Panvel Brochure</h4>
               </div>
               <div className="modal-body">
                  <form action="maildownload.php" method="post">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fnames" placeholder="Name" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="emails" placeholder="Email" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <input type="text" className="form-control" name="mobile" id="mobiles" placeholder="Mobile" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesgs" placeholder="Massage" rows="3"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" name="save" className="btn btn-sl-form"  value="Submit" />
                        </div>
                     </div>
                     <div className="success_message1 pop_align"></div>
                  </form>
               </div>
            </div>
         </div>
      </div>


      <div id="myModals" className="modal fade">
         <div className="modal-dialog">
            <div className="modal-content">
               <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                  <h4 className="modal-title pop_align">Get in touch for Prasadam Panvel </h4>
               </div>
               <div className="modal-body">
                  <form  method="post" id="signupForm2">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fnameb" placeholder="Name" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="emailb" placeholder="Email" required />
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group">
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <div className="col-lg-3">
                              <div className="row">
                                 <select name="code" id="codes" className="form-control" >
                                    <option  value="">Select Country</option>
                                    <option value="+91">India</option>
                                    <option value="+971">United Arab Emirates</option>
                                    <option value="+1">USA</option>
                                    <option value="+1">Canada</option>
                                    <option value="+44">United Kingdom</option>
                                    <option value="+61">Australia</option>
                                    <option value="+65">Singapore</option>
                                    <option value="+974">Qatar</option>
                                    <option value="+968">Oman</option>
                                    <option value="+64">New Zealand</option>
                                    <option value="+60">Malaysia</option>
                                    <option value="+852">Hong Kong</option>
                                    <option value="+0">Others</option>
                                 </select>
                              </div>
                           </div>
                           <div className="col-lg-9">
                              <div className="row">
                                 <input type="text" className="form-control" name="mobile" id="mobileb" placeholder="Mobile" />
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesgb" placeholder="Massage" rows="3"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit" />
                        </div>
                     </div>
                     <div className="success_message2 pop_align"></div>
                  </form>
               </div>
            </div>
         </div>
      </div>

      <div id="feedback">
         <div id="feedback-form" className="col-xs-4 col-md-4 panel panel-default displaynone">
            <h3>Instant Call Back</h3>
            <form  method="post" id="signupForm3">
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-user"></i></span>
                     <input className="form-control" name="fname" id="fnamec" placeholder="Name" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                     <input type="email" className="form-control" name="email" id="emailc" placeholder="Email" />
                  </div>
               </div>
               <div className="form-group">
                  <div className="input-group"> 
                     <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                     <input type="text" className="form-control" name="mobile" id="mobilec" placeholder="Mobile" />
                  </div>
               </div>
               <div className="pop_align">
                  <div className="form-grop">
                     <input type="submit" className="btn btn-sl-form" value="Submit"/>
                  </div>
               </div>
               <div className="success_message3 float_left"></div>
            </form>
         </div>
         <div id="feedback-tab">Instant Call Back</div>
      </div>
    
      <nav id="mainNav" className="navbar navbar-default navbar-fixed-top" >
         <div className="container">
            <div className="navbar-header">
	 
               <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
               <span className="sr-only">Toggle navigation</span> Menu <i className="fa fa-bars"></i>
               </button>
			    
               <a className="navbar-brand page-scroll" href="#page-top">
               <img src="img/wisecitylogo.png" width="153" className="img-responsive logoweb" alt="Wadhwa Wise City" title="Wadhwa Wise City" />
               </a>
            </div>
            <div className="cell" >
               <a href="tel:+919130568016">
                  <div className="col-md-2 cell_phone"><i className="fa fa-phone"></i>&ensp;+91 9130568016</div>
               </a>
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
               <ul className="nav navbar-nav navbar-right">
                  <li><a className="page-scroll" href="#page-top">Home</a></li>
                  <li><a className="page-scroll" href="#about">About</a></li>
                  <li><a className="page-scroll" href="#amenities">Amenities</a></li>
                  <li><a className="page-scroll" href="#plans">Plans</a></li>
                  <li><a className="page-scroll" href="#rate">Pricing</a></li>
                  <li><a className="page-scroll" href="#location">Location</a></li>
                  <li><a className="page-scroll" href="#contact">Contact</a></li>
               </ul>
            </div>
         </div>
      </nav>
    
 
 
		 <header className="container-fluid md-3form">
	     <div className="row">
	     
				<div className="col-md-9 col-md-91">
				<div className="row">
		
			<div className="jssor_1w">
			<div id="jssor_1" className="jssor_1 jssor_1w" >
				 
				<div data-u="slides" className="slider_first">
		
					<div>
						<img data-u="image" alt="Wadhwa Wise City Panvel" src="img/tulsi_slider1.jpg" title="Wadhwa Wise City Panvel" />
						
					</div>
					<div>
						<img data-u="image" alt="Wadhwa Wise City Panvel" src="img/tulsi_slider2.jpg" title="Prasadam Panvel" />
						<div data-u="caption" data-t="0" className="slider_one"><b>New Launch Wadhwa Wise City at Panvel. Luxuries Studio, 1, 2 & 3 BHK Homes.</b>
						
						</div>
					</div>
				</div>
				
				<div style={{display: "block", position: "absolute", top: "40%", left: "30px", width: "70px", height: "150px"}}>
					<div data-u="arrowleft" className="jssora106" style={{width: "75px", height: "75px", top: "0px", left: "0px",}} data-scale="0.75" data-jssor-button="1" data-nofreeze="1">
						<a className="left carousel-control" href="#myCarousel" data-slide="next">
							 
							  <i className="fa fa-arrow-left slicon-left"></i>
							  <span className="sr-only">left</span>
							</a>
					</div>
				</div>
				<div style={{display: "block", position: "absolute", top: "40%", right: "30px", width: "70px", height: "150px"}}>
					<div data-u="arrowright" className="jssora106" style={{width: "75px", height: "75px", top: "0px", left: "0px",}} data-scale="0.75" data-jssor-button="1" data-nofreeze="1">
						<a className="right carousel-control" href="#myCarousel" data-slide="next">
						 
						  <i className="fa fa-arrow-right slicon-right"></i>
						  <span className="sr-only">Next</span>
						</a>
					</div>
				</div>
				
				 
			</div>
			</div>
			<script>jssor_1_slider_init();</script>
			
				</div>
				</div> 

				<div className="col-md-3 col-md-31 sl">
				<div className="row">
				  <div className="sliderform1">
				 
				 
				 <h4>WADHWA WISE CITY - BLUE HILL</h4>
                     <p className="high_label">
					 <span className="animated bounceIn infinite high_label_span"> BIGGEST - Township in Panvel<b><br/> ₹ 34 Lacs Onwards All Inclusive</b></span></p>
					 
					 
                     <form method="post" id="sliderform">
                        <div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-user"></i></span>
                              <input className="form-control" name="fname" id="fnamef" placeholder="Name" required />
                           </div>
                        </div>
                        <div className="form-group">
                           <div className="input-group"> 
                              <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                              <input type="email" className="form-control" name="email" id="emailf" placeholder="Email" required />
                           </div>
                        </div>
                        <div className="form-group">
                           <div className="input-group">
                              <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                              <div className="col-lg-3">
                                 <div className="row">
                                    <input type="text"  className="form-control" name="code" id="codef" placeholder="+91" value="+91" />
                                 </div>
                              </div>
                              <div className="col-lg-9">
                                 <div className="row">
                                    <input type="text"  className="form-control" name="mobile" id="mobilef" placeholder="Mobile" />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12">
                           <div className="form-group">
                              <input type="submit" className="btn btn-sl-form" value="Submit" />
                           </div>
                        </div>
                        <div className="success_message4 pop_align"></div>
                     </form><br/>
		

				<div className="sliderformhr"></div>
 	
				<b> MahaRERA No.:  P52000021082 |  P52000016274 </b>
				 
					<div className="form-title">
						<ul>
						    
						    <li><b> 10:90</b> PAYMENT PLANS</li>
						    <li> Assured Rentals For 2 Years Post Possession</li>
						    <li> Integrated Township of 138 Acres in Panvel</li>
							<li> Studio, 1, 2 & 3 BHK apartments </li>
							<li> Possession : <b> 2022/23</b></li>
							 
							
						</ul>				
					</div>
					
				  </div>
				
				</div> 
				</div> 
	   </div>
    </header>
	
	
 
	
      <section className="contact_div form_mob">
         <div className="container">
            <div className="row">
               <div className="col-md-8 col-md-offset-2 col-sm-9 col-sm-offset-1 text-center" >
                  <h2 className="form_mob_title">Yes, I am Interested in Wadhwa Wise City</h2>
				  <p className="high_label">
					 <span className="animated bounceIn infinite high_label_span"> BIGGEST - Township in Panvel<b><br/> ₹ 34 Lacs Onwards All Inclusive</b></span></p>
                  <hr className="color"/>
                  <form method="post" id="formmobile">
                     <div className="form-group">
                        <input className="form-control form_mob_field" name="fname" id="fnamemid" placeholder="Name" required />
                     </div>
                     <div className="form-group">
                        <input type="email" className="form-control form_mob_field" name="email" id="emailmid" placeholder="Email" required />
                     </div>
                     <div className="form-group">
                        <input type="text" className="form-control form_mob_field" name="mobile" id="mobilemid" placeholder="Mobile" required />
                     </div>
                     <div className="col-md-3 col-md-offset-4">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form"  value="Submit" />
                        </div>
                     </div>
                     <div className="success_mobile pop_align"></div>
                  </form>
                  <div className="div_disc">
				
                    <p> <b> 10:90</b> PAYMENT PLANS</p>
					<p>Assured Rentals For 2 Years Post Possession</p>
					<p> Integrated Township of 138 Acres in Panvel</p>
					<p> Studio, 1, 2 & 3 BHK apartments </p>
					<p> Possession : <b> 2022/23</b></p>
					 
					<div className="animated bounceIn infinite high_label_offer div_discpof"><b>SAVE UPTO 2.67 LACS* BENEFITS<br/> OF LOWEST INTEREST RATE*</b></div>
					
                  </div>
               </div>
            </div>
         </div>
      </section>

    
      <section className="bg-primary1" id="about">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h1 className="section-heading sectionheading1">Wadhwa Wise City</h1>
                  <hr className="light1"/> 
				 
				 
						<p><b>Launching Township Project Wadhwa Wise City at Panvel, Smarter & Premium Homes.</b></p>
						
						
						<p>Presenting <a href="http://wadhwawisecitypanvel1.pre-launch.co/" class="url" >Wadhwa Wise City</a> in Panvel - 1BHK, 2BHK & 3BHK & 4BHK Villas homes Premium Apartments, Residential Projects in Panvel, Navi Mumbai. All residential towers will be 25 stories each. Means living in a township that’s wisely located, wisely planned and wisely designed bring you complete happiness and wellness. presenting Wadhwa Wise city at Panvel, an integrated township project spread across 138 acres, part of 450-acers land parcel to be developed by The Wadhwa Group. Wadhwa Mega Living is a vision of sustainable growth and holds the distinction of being the first-ever integrated township in NAINA (Navi Mumbai Airport Influence Notified Area).</p>
						
						<p>Wadhwa Mega Living Panvel brings you the opportunity to experience a serene country life amidst a bustling megacity. You will be close enough to experience the exciting life of Mumbai and far enough from the madding crowd. The project is nestled in the mountains of Panvel, a suburb of satellite township Navi Mumbai. The proximity to the new airport will make it an aspirational housing project in the months to come and let us assure you the prices will head north too. Navi Mumbai is one of India’s largest planned townships, interestingly the total area of this satellite city is almost equal to Mumbai. Wide roads, metro rail (Ready for launch) efficient bus service and now a brand new airport make an obvious option for new home buyers to buy property here.</p>
						
						<h3 class="div_left">Planning Highlights of the Mega Township</h3>				
							<ul>
								<li> Wadhwa Codename Mega Living has been conceived on the foundations of "Happiness and Wellness".</li>
								<li>Principles of Urban Planning are well integrated to create a cohesive fabric of streets, green spaces, social amenities like schools primary healthcare and a lot more.</li>
								<li>The Natural setting of mountains and hill slopes are conserved and give a natural edge to the proposed settlement.</li>
								<li>Hierarchy of roads and well-defined pathways ensure safe and easy commuting within the township.</li>
								<li>Town level parks and playgrounds and neighbourhood level green open spaces emphasise the health of children and adult citizens.</li>
							</ul>
						
               </div>
            </div>
         </div>
      </section>
    
    
      <section id="amenities" className="bg-primary">
         <div className="container amenities-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Amenities</h2>
                  <hr className="primary"/>
               </div>
            </div>
			
			
			<div className="row1">
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/rock.png" alt="" height="75"/>
								  <p> Rock Climbing </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/running.png" alt="" height="75"/>
								  <p> Jogging Track</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/dumble.png" alt="" height="75"/>
								  <p> Gymnasium </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/squash.png" alt="" height="75"/>
								  <p> Squash Court </p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/pool.png" alt="" height="75"/>
								  <p> Swimming Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/hall.png" alt="" height="75"/>
								  <p> Multipurpose Hall</p>
							</div>
						</div>

						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cafe.png" alt="" height="75"/>
								  <p> Cafe</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/gamepad.png" alt="" height="75"/>
								  <p> Indoor Games Room</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/medtation.png" alt="" height="75"/>
								  <p>Yoga Lawn</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/reflexology.png" alt="" height="75"/>
								  <p> Reflexology Pool</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/kids-pay.png" alt="" height="75"/>
								  <p> Kid's Play Area</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/senior.png" alt="" height="75"/>
								  <p> Senior Citizen's Area</p>
							</div>
						</div>
						
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/ami.png" alt="" height="75"/>
								  <p> Amphith eatre</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/lib.png" alt="" height="75"/>
								  <p> E-Library</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/park.png" alt="" height="75"/>
								  <p> Central Greens</p>
							</div>
						</div>
						<div className="col-md-2 col-xs-6">
							<div className="ame">
								<img src="img/amenities/cctv.png" alt="" height="75"/>
								  <p> Security</p>
							</div>
						</div>
						 
					</div>  
 
	   
         </div>
         
      </section>

      <section id="plans">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Wadhwa Wise City Floor Plans </h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row no-gutter  wow fadeInUp">
               <div className="col-lg-10 col-sm-10 col-xs-12 col-md-offset-2">
				   <div className="col-md-4 col-xs-12">
						<h3 style={{textAlign:"center"}}>Layout Map</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals" >
						 <img src="img/floorplan/layout.jpg"  className="img-responsive" alt="Wadhwa Wise City" title="Wadhwa Wise City"/>
						  
					  </a>
				   </div>
				   <div className="col-md-2 col-xs-12">
				   </div>
				   <div className="col-md-4 col-xs-12">
					<h3 style={{textAlign:"center"}}>Floor Plans</h3>
					  <a href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals" >
						 <img src="img/floorplan/floorplan-hinde.jpg" style={{width:"100%"}} className="img-responsive" alt="Wadhwa Wise City" title="Wadhwa Wise City"/>
						  
					  </a>
				   </div>
               </div>
               			   
			   
            </div>
         </div>
      </section>
    
      <section id="rate" className="rate">
         <div className="container rate-sub1">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading-rate sectionheading2">Wadhwa Wise City Pricing </h2>
                  <hr className="color"/>
               </div>
            </div>
            <div className="col-lg-12 col-sm-12">
               <div className="row">
                  <table className="table table_pr" >
					<thead >
						<tr>
							<th className="table_th">Configuration</th>
							<th className="table_th">Carpet Area</th>
							<th className="table_th">Price</th>
						</tr>
					</thead>
					<tbody className="table_tbody">
						<tr>
							<td className="bg-grey-table1">Studio</td>
							<td className="bg-grey-table1">289 sq ft</td>
							<td className="bg-grey-table2">₹ 34 Lacs All Incl.  
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Click Here</a>
							</td>
						</tr>
						<tr>
							<td className="bg-grey-table1">1 BHK</td>
							<td className="bg-grey-table1">390 sq ft</td>
							<td className="bg-grey-table2">₹ 47 Lacs All Incl. 
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Click Here</a>
							</td>
						</tr>
						 
						<tr>
							<td className="bg-grey-table1">2 BHK</td>
							<td className="bg-grey-table1">494 sq ft</td>
							<td className="bg-grey-table2">₹ 58 Lacs All Incl. 
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Click Here</a>
							</td>
						</tr>
						<tr>
							<td className="bg-grey-table1">2 BHK Supreme</td>
							<td className="bg-grey-table1">628 sq ft</td>
							<td className="bg-grey-table2">₹76 Lacs All Incl. 
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Click Here</a>
							</td>
						</tr>
						<tr>
							<td className="bg-grey-table1">3 Bed + Terrace Villa </td>
							<td className="bg-grey-table1">1756 sq ft</td>
							<td className="bg-grey-table2">
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Price On Request</a>
							</td>
						</tr>
						<tr>
							<td className="bg-grey-table1">4 Bed + Family Room Villa </td>
							<td className="bg-grey-table1">2189 sq ft</td>
							<td className="bg-grey-table2">
							<a className="price-click" href="#" data-toggle="modal" data-value="2 BHK" data-target="#myModals">Price On Request</a>
							</td>
						</tr>
						<tr>
							<td className="bg-grey-table1 note-size" colspan="3"><b>10:90 Subvention</b> Scheme 10% only & nothing till possession No EMI & Extra Cost Loaded Lowest Price Guaranteed</td>
						</tr>
						<tr>
							<td className="bg-grey-table2" colspan="3"><a href="#" data-toggle="modal" className="btn btn-price" data-target="#myModals">Enquire Now</a></td>
						</tr>

					</tbody>
				</table>  
               </div>
               <h3 className="pop_align1">For booking process please call us now: +91 9130568016</h3>
            </div>
         </div>
      </section>
    
      <section id="location">
         <div className="container">
            <div className="row">
               <div className="col-lg-12 text-center">
                  <h2 className="section-heading sectionheading1">Wadhwa Wise City Location</h2>
                  <hr className="primary"/>
               </div>
            </div>
         </div>
         <div className="container">
            <div className="row">
				<div className="col-lg-7 col-sm-7 pop_align">
					<div className="col-lg-12 col-sm-12">	
						 <a href="#myModals">
						 <img src="img/location.jpg" alt="Location Wadhwa Wise City" title="Wadhwa Wise City" data-toggle="modal" data-target="#myModals" className="img-responsive pop_align" /><br/>
						 </a>
					</div>
			    </div> 
				<div className="col-sm-5">
					<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingOne">
								<h4 className="panel-title">
									<a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										EDUCATION WITHIN 10-20 MINS
									</a>
								</h4>
							</div>
							<div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
								<div className="panel-body">
									  <ul>
										<li>St. Wilfred School</li>
										<li>Wilfred College of Arts & Commerce </li>
										<li>Chhatrapati Shivaji Maharaj Institute of  Technology </li>
										<li>DSP Panvel </li>
										<li>Amity University</li>
									  </ul>
								</div>
							</div>
						</div>

						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingTwo">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										ENTERTAINMENT WITHIN 15-25 MINS
									</a>
								</h4>
							</div>
							<div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
								<div className="panel-body">
									<ul>
										<li>Orion Mall </li>
										<li>K mall </li>
										<li>Golf Course</li>
										<li>Adlabs Imagica </li>
										<li>Della Adventures </li>
										<li>Karala Caves</li>
									  </ul>
								</div>
							</div>
						</div>

						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingThree">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										TRANSPORT NODES WITHIN 10 MINS
									</a>
								</h4>
							</div>
							<div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
								<div className="panel-body">
									<ul>
										<li>Shedung Plaza </li>
										<li>New Mumbai-Pune Expressway </li>
										<li>Mohape Railway Station </li>
										<li>Thombrewadi Bus Stop </li>
										<li>Mumbai-Pune Express Way</li>
									 </ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingfour">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										TRANSPORT WITHIN 15-25 MINS
 
									</a>
								</h4>
							</div>
							<div id="collapsefour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
								<div className="panel-body">
									<ul>
										<li>Palapse Phata</li>
										<li>Navi Mumbai International Airport</li>
										<li>Panvel Bus Terminal</li>
										<li>Panvel Railway Station</li>
									  </ul>
								</div>
							</div>
						</div>
						<div className="panel panel-default">
							<div className="panel-heading" role="tab" id="headingfive">
								<h4 className="panel-title">
									<a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
										<i className="more-less fa fa-plus" aria-hidden="true"></i>
										HEALTHCARE 10-20 MINS 
									</a>
								</h4>
							</div>
							<div id="collapsefive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
								<div className="panel-body">
									<ul>
										<li>Dhirubhai Ambani Hospital</li>
										<li>Lifeline Hospital</li>
									  </ul>
								</div>
							</div>
						</div>
						
						
						</div>
				</div>

			   
            </div>
			
         </div>
      </section>

      <aside className="bg-dark">
         <div className="container text-center">
            <div className="call-to-action">
               <h2>For more Information call : +91 9130568016</h2>
               <a href="#myModal" data-toggle="modal" data-target="#myModal" className="btn btn-default btn-xl sr-button">Download Brochure!</a>
            </div>
         </div>
      </aside>

      
	  
	 

 <section id="contact"  >
         <div className="container">
            <div className="row">
				<div className="col-lg-6 text-center">
                  <h3 className="section-heading">About Wadhwa Group</h3>
                  <hr className="light"/>

						<p>The Wadhwa Group carries a rich legacy of over half a century built on the trust and belief of our customers and stakeholders. The group is one of Mumbai's leading real estate companies and is currently developing residential, commercial and township projects spread across approximately 1.4 million square meters (15 million square feet). Timely completion of projects coupled with strong planning and design innovation gives the group an edge over its competitors. As an organization, the group is also known for its employee-friendly and professional working environment. Today, the group's clientele comprises of over 20,000 satisfied customers and over 100 MNC corporate tenants.</p>

                   
               </div>
			   
               <div className="col-lg-6  text-center">
                  <h3 className="section-heading">Lets Get In Touch! </h3>
                  <hr className="primary"/>
                  <form method="post" id="contactinq">
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-user"></i></span>
                           <input className="form-control" name="fname" id="fname" placeholder="Name" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                           <input type="email" className="form-control" name="email" id="email" placeholder="Email" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-phone-square" ></i></span>
                           <input type="text" className="form-control" name="mobile" id="mobile" placeholder="Mobile" required/>
                        </div>
                     </div>
                     <div className="form-group">
                        <div className="input-group"> 
                           <span className="input-group-addon"><i className="fa fa-comment"></i></span>
                           <textarea className="form-control" name="mesg" id="mesg" placeholder="Massage" rows="4"></textarea>
                        </div>
                     </div>
                     <div className="pop_align">
                        <div className="form-group">
                           <input type="submit" className="btn btn-sl-form" value="Submit"/>
                        </div>
                     </div>
                     <div className="success_message pop_align"></div>
                     <br/>
                  </form>
               </div>
               <div className="col-lg-12  text-center">
                  <i className="fa fa-phone fa-3x sr-contact"></i>
                  <h3>+91 9130568016</h3>
               </div>
            </div>
         </div>
      </section>

      <div className="bg-dark">
         <div className="container text-center">
            <div>
               <ul className="footer-menu">
                  <li><a href="https://www.facebook.com/" target="_blank">Facebook</a></li>
                  <li>|</li>
                  <li><a href="https://plus.google.com/" target="_blank">Google +</a></li>
                  <li>|</li>
                  <li><a href="https://twitter.com/" target="_blank">Twitter</a></li>
               </ul>
               <div className="disclaimer"><b>Disclaimer:</b> The content and Images is for information purposes only. Prices mentioned are subject to change without notice and properties mentioned are subject to availability. Images for representation purpose only. Authorized Channel Sales Partners, not official website. All Rights Reserved.</div>
               <div className="disclaimer"><b>Project - MahaRERA No.:</b> P52000016524 & P52000020776</div>
               <hr class="color" />
               <div className="sitemaplink"><a href='sitemap.html' target='_blank'>Sitemap</a></div>
            </div>
         </div>
      </div>

      <div className="whatsapp-area">
         <div className="chat-button-area zoomOut">
            <button type="button" id="whatsapp-btn"  className="whatsapp-btn"> 
            <a href="https://wa.me/919082164514?text=I'm%20interested%20in%20Kalpataru%20Park Riviera%20Panvel%20Project" target="_blank">
            <span className="icon-whatsapp"></span> <i className="fa fa-whatsapp"></i>
            </a>
            </button> 
            <div className="circle-animation">
            </div>
         </div>
      </div>
    
    </div>
  );
}

export default App;
